
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { DtoClaimsGetResImple } from "mediadept-common/src/common/dto/DtoClaimsGetResImple";
import { DtoMastersGetRes } from "mediadept-common/src/common/dto/DtoMastersGetRes";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "mediadept-common/src/common/dto/DtoMastersGetReq";
import { DtoClaimsGetReqImple } from "mediadept-common/src/common/dto/DtoClaimsGetReqImple";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import { Cl_claim } from "@/functions/Cl_claim";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";
import { DtoFileReportsGetReqImple } from "mediadept-common/src/common/dto/DtoFileReportsGetReqImple";
import { DtoOutputqueuesGetReqImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { DtoOutputqueuesGetResImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetResImple";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { DtoMdDtp } from "mediadept-common/src/common/dto/DtoMdDtp";
import Mt_dtp4month from "@/components/atoms/Mt_dtp4month.vue";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";

import Or_selectClient from "@/components/organisms/Or_selectClient.vue";
import { EntityClient } from "mediadept-common/src/common/entity/EntityClient";
import { Watch } from "vue-property-decorator/lib/decorators/Watch";
import { CONST_OP_CLAIM_STATUS } from "mediadept-common/src/common/constant/Constants";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
    Mt_dtp,
    Mt_dtp4month,
    Or_selectClient,
  },
})
export default class Seikyulist extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoClaimsGetResImple();
  delegateStaff = new DtoMastersGetRes();
  delegateClient = new DtoMastersGetRes();

  //ボタン制御用
  btnDisable = false;

  //検索条件dom
  domClaimNo_start = "";
  domClaimNo_end = "";
  domClaimDate_start = new DtoMdDtp();
  domClaimDate_end = new DtoMdDtp();
  domClientid: number | null = null;
  domClaimTitle = "";
  domClaimSponsorName = "";
  domClaimPayLimit_start = new DtoMdDtp();
  domClaimPayLimit_end = new DtoMdDtp();
  domStaffId = "";
  domClaimStatus = ""; //0:未承認未出力;1:承認依頼済み未承認;2:承認済み未出力;3:出力済;9:差戻済み未承認
  domSalesMonth_start = new DtoMdDtp();
  domSalesMonth_end = new DtoMdDtp();

  domCb1_builbord = true;
  domCb2_posterjack = true;
  domCb3_ComFacility = true;
  domCb4_digitalBuilBoard = true;
  domCb5_transportation = true;
  domCb6_others = true;

  // optionsOp3 = [
  //   { label: "未発行", code: 1 },
  //   { label: "発行済み", code: 2 },
  // ];

  optionsOp3 = CONST_OP_CLAIM_STATUS;

  columns = [
    {
      label: "請求",
      field: "btnDetClaim",
    },
    {
      label: "受注",
      field: "btnDetProject",
    },
    {
      label: "請求No",
      field: "c_claim_number",
    },
    {
      label: "案件No",
      field: "c_project_number",
    },
    {
      label: "発行日",
      field: "c_claim_datetime_short",
      type: "date",
      dateInputFormat: "MM/dd/yyyy",
      dateOutputFormat: "yyyy/MM/dd",
      thClass: "text-center",
      tdClass: "text-center",
    },
    // ****************************************
    // TODO:#3782 バッジ復元
    // {
    //   label: "金額一致",
    //   field: "c_sales_total_mismatch",
    // },
    // ****************************************
    {
      label: "請求先",
      field: "c_client_name",
    },
    {
      label: "広告主",
      field: "c_claim_sponsor_name",
    },
    {
      label: "件名",
      field: "c_claim_title_1",
    },
    {
      label: "税抜金額",
      field: "c_claim_subtotal",
    },
    {
      label: "請求合計",
      field: "c_claim_total",
    },
    {
      label: "担当者",
      field: "c_staff_name",
    },
  ];

  // //請求先選択用
  evDatas: {
    selectedItem: EntityClient | null;
    isSelected: boolean;
  } = {
    selectedItem: null,
    isSelected: false,
  };

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getlist(1);
    await this.getStaffList();
    await this.getClientList();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoClaimsGetReqImple();

      //検索条件設定
      dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.cursor = currentPage;

      if (this.domClaimNo_start != "")
        dto.claimNo_start = Number(this.domClaimNo_start);

      if (this.domClaimNo_end != "")
        dto.claimNo_end = Number(this.domClaimNo_end);

      // dto.ClaimDate_start = this.domClaimDate_start;
      if (this.domClaimDate_start.date != null) {
        dto.ClaimDate_start = new Date(this.domClaimDate_start.date);
      }

      // dto.ClaimDate_end = this.domClaimDate_end;
      if (this.domClaimDate_end.date != null) {
        dto.ClaimDate_end = new Date(this.domClaimDate_end.date);
      }

      if (this.domClientid != null) dto.Clientid = Number(this.domClientid);

      dto.ClaimTitle = this.domClaimTitle;

      dto.ClaimSponsorName = this.domClaimSponsorName;

      // dto.ClaimPayLimit_start = this.domClaimPayLimit_start;
      if (this.domClaimPayLimit_start.date != null) {
        dto.ClaimPayLimit_start = new Date(this.domClaimPayLimit_start.date);
      }

      // dto.ClaimPayLimit_end = this.domClaimPayLimit_end;
      if (this.domClaimPayLimit_end.date != null) {
        dto.ClaimPayLimit_end = new Date(this.domClaimPayLimit_end.date);
      }

      if (this.domStaffId != "" && this.domStaffId != null)
        dto.StaffId = Number(this.domStaffId);

      if (this.domClaimStatus != "" && this.domClaimStatus != null)
        // dto.ClaimStatus = Number(this.domClaimStatus);
        dto.ClaimStatus = this.domClaimStatus;

      if (this.domSalesMonth_start.date != null) {
        dto.SalesMonth_start = new Date(this.domSalesMonth_start.date);
      }

      if (this.domSalesMonth_end.date != null) {
        dto.SalesMonth_end = new Date(this.domSalesMonth_end.date);
      }

      //商品種別
      //-------
      if (this.domCb1_builbord) {
        dto.itemType1_builbord = 1;
      }
      if (this.domCb2_posterjack) {
        dto.itemType2_posterjack = 1;
      }
      if (this.domCb3_ComFacility) {
        dto.itemType3_ComFacility = 1;
      }
      if (this.domCb4_digitalBuilBoard) {
        dto.itemType4_digitalBuilBoard = 1;
      }
      if (this.domCb5_transportation) {
        dto.itemType5_transportation = 1;
      }
      if (this.domCb6_others) {
        dto.itemType6_others = 1;
      }

      //API実行
      //-------
      this.delegate = await Cl_claim.getList(dto);

      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoClaimsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "SeikyuList";

      if (this.domClaimNo_start != "")
        dto.claimNo_start = Number(this.domClaimNo_start);

      if (this.domClaimNo_end != "")
        dto.claimNo_end = Number(this.domClaimNo_end);

      // dto.ClaimDate_start = this.domClaimDate_start;
      if (this.domClaimDate_start.date != null) {
        dto.ClaimDate_start = new Date(this.domClaimDate_start.date);
      }

      // dto.ClaimDate_end = this.domClaimDate_end;
      if (this.domClaimDate_end.date != null) {
        dto.ClaimDate_end = new Date(this.domClaimDate_end.date);
      }

      if (this.domClientid != null) dto.Clientid = Number(this.domClientid);

      dto.ClaimTitle = this.domClaimTitle;

      dto.ClaimSponsorName = this.domClaimSponsorName;

      // dto.ClaimPayLimit_start = this.domClaimPayLimit_start;
      if (this.domClaimPayLimit_start.date != null) {
        dto.ClaimPayLimit_start = new Date(this.domClaimPayLimit_start.date);
      }

      // dto.ClaimPayLimit_end = this.domClaimPayLimit_end;
      if (this.domClaimPayLimit_end.date != null) {
        dto.ClaimPayLimit_end = new Date(this.domClaimPayLimit_end.date);
      }

      if (this.domStaffId != "" && this.domStaffId != null)
        dto.StaffId = Number(this.domStaffId);

      if (this.domClaimStatus != "" && this.domClaimStatus != null)
        dto.ClaimStatus = Number(this.domClaimStatus);

      if (this.domSalesMonth_start.date != null) {
        dto.salesMonth_start = new Date(this.domSalesMonth_start.date);
      }

      if (this.domSalesMonth_end.date != null) {
        dto.salesMonth_end = new Date(this.domSalesMonth_end.date);
      }

      //商品種別
      //-------
      if (this.domCb1_builbord) {
        dto.itemType1_builbord = 1;
      }
      if (this.domCb2_posterjack) {
        dto.itemType2_posterjack = 1;
      }
      if (this.domCb3_ComFacility) {
        dto.itemType3_ComFacility = 1;
      }
      if (this.domCb4_digitalBuilBoard) {
        dto.itemType4_digitalBuilBoard = 1;
      }
      if (this.domCb5_transportation) {
        dto.itemType5_transportation = 1;
      }
      if (this.domCb6_others) {
        dto.itemType6_others = 1;
      }

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);
      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dto2 = new DtoOutputqueuesGetReqImple();
      dto2.c_outputqueue_code = res.outputqueue_code;
      let res2 = new DtoOutputqueuesGetResImple();
      res2 = await Cl_outputqueue.get(dto2);
      while (now < timeLimit) {
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        res2 = await Cl_outputqueue.get(dto2);
        if (res2.c_outputqueue_flag_end) {
          break;
        }
      }

      //レスポンス処理
      //-------
      if (res2.c_outputqueue_flag_success) {
        location.href = res2.url;
      } else {
        throw new Error("出力失敗");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   *社員プルダウン取得用
   */
  async getStaffList() {
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //TODO:#4134
      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4staffSortNum = 1;
      //表示用
      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //API実行
      //-------
      this.delegateStaff = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateStaff);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateStaff = new DtoMastersGetRes();
      Cl_common.logger(error);
    }
  }

  /**
   *顧客プルダウン取得用
   */
  async getClientList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行顧客マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 1;
      //休日用準備
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegateClient = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateClient);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateClient = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 顧客選択時のイベント
   * watch用
   */
  @Watch("evDatas.isSelected")
  async selectClient() {
    if (!this.evDatas.isSelected) {
      return;
    }
    this.domClientid = this.evDatas.selectedItem!.c_client_id!;
  }

  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  /**
   * 画面遷移用
   * @param url
   * @param event
   */
  async goTo(url: string, event?: MouseEvent) {
    Cl_common.goTo(url, this.$router, event);
  }
  cnvDetails(price: any) {
    let cnvPrice;

    //金額
    if (price != null) {
      cnvPrice = price!.toLocaleString("ja-JP");
    } else {
      cnvPrice = "";
    }
    return cnvPrice;
  }
  get_url_claim(params: any) {
    let url = "";
    if (params == 1) {
      url = "seikyusyocreate?claimnum=";
    } else {
      url = "seikyusyocreatenotsales?claimnum=";
    }
    return url;
  }

  cnvStatusNum2Name(flag: number): string {
    // Cl_common.logger("flag");
    // Cl_common.logger(flag);
    if (1 == flag) {
      return '<span class="badge badge-danger badge-pill" style="font-size: 1em;">不一致</span>';
    } else {
      return '<span class="badge badge-info badge-pill" style="font-size: 1em;">一致</span>';
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }

  /**
   * 請求先選択用モーダル
   */
  async showModalclaim(): Promise<void> {
    this.evDatas.selectedItem = null;
    this.evDatas.isSelected = false;

    const modalClient: any = this.$refs.Mo_modal_selectType;
    await modalClient.showModal();
  }
  async closeModalclaim(): Promise<void> {
    const modalClient: any = this.$refs.Mo_modal_selectType;
    modalClient.closeModal();
  }
}

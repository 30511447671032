
import { Options, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";

import Mo_chart_doughnut from "@/components/molecules/Mo_chart_doughnut.vue";
import Mo_chart_barline from "@/components/molecules/Mo_chart_barline.vue";
import { ChartOptions } from "chart.js";
import { ref } from "vue";
import { Cl_common } from "@/functions/Cl_common";
import {
  DtoAggregatesGetReq,
  e_aggTypes,
} from "mediadept-common/src/common/dto/DtoAggregatesGetReq";
import { DtoAggregatesGetRes } from "mediadept-common/src/common/dto/DtoAggregatesGetRes";
import { Cl_aggregates } from "@/functions/Cl_aggregates";
import axios from "axios";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { EntityAgg4client4rank } from "mediadept-common/src/common/entity/EntityAgg4client4rank";
import Mt_dtp4month from "@/components/atoms/Mt_dtp4month.vue";
import { DtoMdDtp } from "mediadept-common/src/common/dto/DtoMdDtp";
import { CommonDate4MD } from "mediadept-common/src/common/function/CommonDate4MD";
import { commonDate } from "xcommon-node/src/common/commonDate";
import { predictGender } from "mediadept-common/src/common/constant/Constants";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_chart_doughnut,
    Mo_chart_barline,
    Mo_modal_alert,
    Mt_dtp4month,
  },
})
export default class Analyprofit2 extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoAggregatesGetRes();
  btnDisable = false;

  reRender = true;

  //----------------------
  //DOM連携用メソッド
  //----------------------
  domMonth_start = new DtoMdDtp();
  domMonth_end = new DtoMdDtp();
  domBisYear = 0;
  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";
  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    // 期間指定UI用
    // await this.setDtp4NowBD();
    //期指定UI用
    await this.setSelect4NowBD();
    // await this.getMastersList();
    //TODO:表示タブを認識して、更新を処理を実行するメソッドに変更する。
    this.get4All();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  @Watch("domBisYear")
  async get4All() {
    //連続でエラーを表示させないために、日付の検証はここで行う。
    // 必要に応じて、個別の処理にも実装する。

    // 期間指定UI用
    // if (!(await this.isValid())) {
    //   return;
    // }

    this.get4Batch_0();
    this.get4Summary_graph_100();
    // this.get4Summary_liet_200();
    this.get4Client_graph_300();
    this.get4Client_genre_400();
    this.get4Client_rank_500();
    this.get4Staff_list_1900();
  }
  /**
   * 日付の検証を実行する。
   */
  async isValid(): Promise<boolean> {
    //日付の検証

    let dto = new DtoAggregatesGetReq();

    dto.dateStrat = this.domMonth_start.date;
    dto.dateEnd = this.domMonth_end.date;
    dto.aggType = e_aggTypes.badge_0;

    const validMessage = await dto.IsValidImple(true);
    if (validMessage.length != 0) {
      this.message4ModalErrorConf = validMessage[0];
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
      return false;
    }

    return true;
  }

  /**
   * -----------------
   * １．全体
   * -----------------
   */
  async get4Batch_0() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    //ローダーはパネル毎の独立制御の為、バッチは処理しない。
    // const panel = document.getElementsByClassName("panel-container");
    // Array.from(panel).map((element: Element) => {
    //   element.classList.add("enable-loader");
    // });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //期指定UI用、期を期間に変換
      this.cnvSelect4Term();

      let dto = new DtoAggregatesGetReq();

      dto.dateStrat = this.domMonth_start.date;
      dto.dateEnd = this.domMonth_end.date;
      dto.aggType = e_aggTypes.badge_0;

      //API実行
      //-------
      const response = await Cl_aggregates.get(dto);

      this.delegate.Badge_0_1_sales = response.Badge_0_1_sales;
      this.delegate.Badge_0_2_profit = response.Badge_0_2_profit;
      this.delegate.Badge_0_3_gale2salseRatio =
        response.Badge_0_3_gale2salseRatio;
      this.delegate.Badge_0_4_gale2profitRatio =
        response.Badge_0_4_gale2profitRatio;
      this.delegate.Badge_0_5_profitRatio = response.Badge_0_5_profitRatio;

      //レスポンス処理
      //-------
    } catch (error) {
      Cl_common.logger(error);

      this.message4ModalErrorConf = "エラーが発生しました";

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      //ローダーはパネル毎の独立制御の為、バッチは処理しない。
      // Array.from(panel).map((element: Element) => {
      //   element.classList.remove("enable-loader");
      // });
    }
  }
  async get4Summary_graph_100() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    // const panel = document.getElementsByClassName("panel-container");
    // Array.from(panel).map((element: Element) => {
    //   element.classList.add("enable-loader");
    // });
    const panel = document.getElementById("panel-100_summary-graph");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //期指定UI用、期を期間に変換
      this.cnvSelect4Term();

      let dto = new DtoAggregatesGetReq();

      dto.dateStrat = this.domMonth_start.date;
      dto.dateEnd = this.domMonth_end.date;
      dto.aggType = e_aggTypes.summary_graph_100;

      //API実行
      //-------
      this.delegate.summary_graph_100_chartData = (
        await Cl_aggregates.get(dto)
      ).summary_graph_100_chartData;
      //レスポンス処理
      //-------
    } catch (error) {
      Cl_common.logger(error);

      this.message4ModalErrorConf = "エラーが発生しました";

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
    }
  }
  async get4Summary_liet_200() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-200_summary-list");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //期指定UI用、期を期間に変換
      this.cnvSelect4Term();

      let dto = new DtoAggregatesGetReq();

      dto.dateStrat = this.domMonth_start.date;
      dto.dateEnd = this.domMonth_end.date;
      dto.aggType = e_aggTypes.summary_list_200;

      //API実行
      //-------
      this.delegate.summary_list_200_listData = (
        await Cl_aggregates.get(dto)
      ).summary_list_200_listData;
      //レスポンス処理
      //-------
    } catch (error) {
      Cl_common.logger(error);

      this.message4ModalErrorConf = "エラーが発生しました";

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * -----------------
   * ２．売上
   * -----------------
   */
  async analy4Client() {
    this.get4Summary_liet_200();
    this.get4Client_graph_300();
    this.get4Client_genre_400();
    this.get4Client_rank_500();
  }

  async get4Client_graph_300() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-300_client-graph");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //期指定UI用、期を期間に変換
      this.cnvSelect4Term();

      let dto = new DtoAggregatesGetReq();

      dto.dateStrat = this.domMonth_start.date;
      dto.dateEnd = this.domMonth_end.date;
      dto.aggType = e_aggTypes.client_graph_300;

      //API実行
      //-------
      const response = await Cl_aggregates.get(dto);
      //レスポンス処理
      //-------

      this.delegate.client_graph_300_chartData_now =
        response.client_graph_300_chartData_now;
      this.delegate.client_graph_300_chartData_now4Suupy =
        response.client_graph_300_chartData_now4Suupy;
      this.delegate.client_graph_300_chartData_now4Profit =
        response.client_graph_300_chartData_now4Profit;

      this.delegate.client_graph_300_chartData_onePrev =
        response.client_graph_300_chartData_onePrev;
      this.delegate.client_graph_300_chartData_onePrev4Suupy =
        response.client_graph_300_chartData_onePrev4Suupy;
      this.delegate.client_graph_300_chartData_onePrev4Profit =
        response.client_graph_300_chartData_onePrev4Profit;

      // this.delegate.client_graph_300_chartData_twoPrev =
      //   response.client_graph_300_chartData_twoPrev;
      this.delegate.client_graph_300_max_x = response.client_graph_300_max_x;

      //TODO:以下の記述をスマートにしたい。
      this.chartOptions4ClientGraph300 = ref<ChartOptions<"bar">>({
        responsive: true,
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
            position: "top",
          },
          title: {
            display: false,
          },
        },
        scales: {
          xAxis: {
            axis: "x",
            stacked: true,
            max: this.delegate.client_graph_300_max_x,
          },
          yAxis: {
            axis: "y",
            stacked: true,
          },
        },
      });
    } catch (error) {
      Cl_common.logger(error);

      this.message4ModalErrorConf = "エラーが発生しました";

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
    }
  }
  async get4Client_genre_400() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-400_genre-graph");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //期指定UI用、期を期間に変換
      this.cnvSelect4Term();

      let dto = new DtoAggregatesGetReq();

      dto.dateStrat = this.domMonth_start.date;
      dto.dateEnd = this.domMonth_end.date;
      dto.aggType = e_aggTypes.client_genre_400;

      //API実行
      //-------
      const response = await Cl_aggregates.get(dto);
      //レスポンス処理
      //-------

      this.delegate.client_graph_400_chartData =
        response.client_graph_400_chartData;
    } catch (error) {
      Cl_common.logger(error);

      this.message4ModalErrorConf = "エラーが発生しました";

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
    }
  }
  async get4Client_rank_500() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-500_rank-list");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //期指定UI用、期を期間に変換
      this.cnvSelect4Term();

      let dto = new DtoAggregatesGetReq();

      dto.dateStrat = this.domMonth_start.date;
      dto.dateEnd = this.domMonth_end.date;
      dto.aggType = e_aggTypes.client_ranking_500;

      //API実行
      //-------
      const response = await Cl_aggregates.get(dto);
      //レスポンス処理
      //-------

      this.delegate.client_list_500_listData =
        response.client_list_500_listData;
    } catch (error) {
      Cl_common.logger(error);

      this.message4ModalErrorConf = "エラーが発生しました";

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
    }
  }

  /**
   * -----------------
   * ６．スタッフ
   * -----------------
   */
  async get4Staff_list_1900() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-1900_staff-list");
    panel?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //期指定UI用、期を期間に変換
      this.cnvSelect4Term();

      let dto = new DtoAggregatesGetReq();

      dto.dateStrat = this.domMonth_start.date;
      dto.dateEnd = this.domMonth_end.date;
      dto.aggType = e_aggTypes.staff_list_1900;

      //API実行
      //-------
      const response = await Cl_aggregates.get(dto);

      this.delegate.staff_list_1900_listData =
        response.staff_list_1900_listData;

      //レスポンス処理
      //-------
    } catch (error) {
      Cl_common.logger(error);

      this.message4ModalErrorConf = "エラーが発生しました";

      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
    }
  }

  //-------------------
  /**
   * 利益率を計算して返す。
   * @param item
   */
  cnvProfitRatio(item: EntityAgg4client4rank): string {
    const tmp = (item.sumProfit! / item.sumSales!) * 100;

    if (!isNaN(tmp) && tmp != -Infinity) {
      return tmp.toFixed(2);
    } else {
      return "0.00";
    }
  }
  /**
   * 画面遷移用
   * @param url
   * @param event
   */
  async goTo(url: string, event?: MouseEvent) {
    Cl_common.goTo(url, this.$router, event);
  }
  /**
   * 個人分析画面遷移用
   * @param url
   * @param event
   */
  async goTo4parsonal(staffid: number | null, event?: MouseEvent) {
    if (staffid == null) {
      return;
    }

    //期指定UI用、期を期間に変換
    this.cnvSelect4Term();

    let url =
      "/analyprofitPrsonal?sid=" +
      staffid +
      "&sdate=" +
      this.domMonth_start.date +
      "&edate=" +
      this.domMonth_end.date;

    Cl_common.goTo(url, this.$router, event);
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  /**
   * エラーモーダルを閉じる。
   */
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
  //----------------------
  //その他のメソッド（画面独自のもの等）
  //この画面では、グラフのオプション等もここに記述する。
  //----------------------
  /**
   * 100売上詳細グラフ用オプション
   */
  chartOptions4SalesGraph100 = ref<ChartOptions<"bar">>({
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        axis: "x",
        // stacked: true,
      },
      "y-axis-1": {
        // stacked: true,
        title: {
          display: true,
          text: "月別売上額(円)",
        },
      },
      "y-axis-2": {
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: "累積売上額(円)",
        },
        ticks: {
          display: true,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  });
  /**
   * 300顧客別売上積上棒グラフ用オプション
   * 子の記載は初期値、APIのレスポンスで上書きされる。
   */
  chartOptions4ClientGraph300 = ref<ChartOptions<"bar">>({
    responsive: true,
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        axis: "x",
        stacked: true,
        max: this.delegate.client_graph_300_max_x,
      },
      yAxis: {
        axis: "y",
        stacked: true,
      },
    },
  });
  /**
   * 400当期業種別売上棒グラフ用オプション
   */
  chartOptions4GenreGraph400 = ref<ChartOptions<"bar">>({
    responsive: true,
    indexAxis: "y",
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        axis: "x",
        // stacked: true,
      },
      yAxis: {
        axis: "y",
        // stacked: true,
      },
    },
  });

  /**
   * -------------------------------------------------
   * 汎用メソッド。
   * -------------------------------------------------
   */
  /**
   * 対象期を指定するSelectに当期をセットする
   */
  async setSelect4NowBD() {
    //当期をセットする
    const bd = CommonDate4MD.getBusinessDate(new Date());

    // this.domMonth_start.date = "2025-10";
    this.domBisYear = bd[0].getFullYear();
  }
  /**
   * 期指定UIの指定値を、期間指定UI用に変換する。
   * 基本的に、DTOで使用するのは期間指定となる。
   */
  async cnvSelect4Term() {
    //指定年の4月1日を取得
    const startDate = new Date(this.domBisYear, 3, 1);

    //期間に変換
    const bd = CommonDate4MD.getBusinessDate(startDate);
    this.domMonth_start.date = CommonDate4MD.getLastDayOfMonth_str4Dtp(bd[0]);
    this.domMonth_end.date = CommonDate4MD.getLastDayOfMonth_str4Dtp(bd[1]);
  }

  /**
   * 対象範囲日付を指定するDTPに当期をセットする
   */
  async setDtp4NowBD() {
    //当期をセットする
    const bd = CommonDate4MD.getBusinessDate(new Date());

    // this.domMonth_start.date = "2025-10";
    this.domMonth_start.date = CommonDate4MD.getLastDayOfMonth_str4Dtp(bd[0]);
    this.domMonth_end.date = CommonDate4MD.getLastDayOfMonth_str4Dtp(bd[1]);
  }
  /**
   * 対象範囲日付を指定するDTPに今月をセットする
   */
  async setDtp4Now() {
    //当期をセットする
    const bd = CommonDate4MD.getBusinessDate(new Date());

    // this.domMonth_start.date = "2025-10";
    this.domMonth_start.date = CommonDate4MD.getLastDayOfMonth_str4Dtp(bd[0]);
    this.domMonth_end.date = CommonDate4MD.getLastDayOfMonth_str4Dtp(
      new Date()
    );
  }
  /**
   * 渡された文字列の最初の一文字を返す。
   */
  getFirstChra(val: string | null): string {
    if (val == null) {
      return "未";
    }
    return val[0];
  }
  /**
   * スタッフ名を返す。
   */
  getStaffName(val: string | null): string {
    if (val == null) {
      return "担当者未設定";
    }
    return val;
  }
  /**
   * staffアイコンの色を返す。
   * @param val
   */
  getColor4Staff(val: string | null): number {
    return predictGender(val);
  }

  /**
   * -------------------------------------------------
   * 以下はモック時のコード
   * -------------------------------------------------
   */

  optionsOp = [
    {
      label: "メディアサービス",
    },
    {
      label: "Officeroom",
    },
    {
      label: "フラッグ",
    },
    {
      label: "クラフトワールドワイド",
    },
    {
      label: "ジーニー",
    },
    {
      label: "東映エージエンシー ",
    },
    {
      label: "サニーサイドアップ",
    },
    {
      label: "日本中央競馬会",
    },
    {
      label: "TATRAS INTERNATIONAL",
    },
    {
      label: "Nomad Tokyo合同会社",
    },
    {
      label: "シグナル",
    },
    {
      label: "ゴラク",
    },
  ];

  optionsOp4 = [
    { label: "広告" },
    { label: "製造/製作" },
    { label: "印刷" },
    { label: "イベント" },
    { label: "通信/放送サービス" },
    { label: "ファッション" },
    { label: "美容" },
    { label: "化粧品" },
    { label: "端末" },
    { label: "家電" },
    { label: "自動車/バイク" },
    { label: "音楽" },
    { label: "スポーツ" },
    { label: "旅行" },
    { label: "ゲーム" },
    { label: "書籍" },
    { label: "映画" },
    { label: "食品" },
    { label: "飲料" },
    { label: "外食" },
    { label: "運輸・観光" },
    { label: "教育" },
    { label: "医療/医薬" },
    { label: "証券" },
    { label: "不動産" },
    { label: "金融/保険" },
    { label: "消費者金融" },
    { label: "官庁" },
    { label: "政治/宗教" },
    { label: "マッチング" },
    { label: "ギャンブル" },
    { label: "その他" },
  ];

  // -------------------
}
